import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Text,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import UpdateProfileModal from './pop-up/updateProfile';
import TeamSection from './components/TeamSection'; // Import TeamSection
import JoinTeamSection from './components/JoinTeamSection';
import ProfileService from './services/ProfileService';
import { useAuth } from 'react-oidc-context';
import DeleteProfileModal from './pop-up/deleteProfileModal';

const MyProfile: React.FC = () => {
  const placeholderUserName = 'Nume';
  const auth = useAuth();

  const [name, setName] = useState<string>(placeholderUserName);
  const [inTeam, setInTeam] = useState<boolean>(false); // Use state for inTeam

  const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure();
  const { isOpen: isLeaveOpen, onOpen: onLeaveOpen, onClose: onLeaveClose } = useDisclosure(); // Manage Leave Account modal
  const [hasCv, setHasCv] = useState(false);

  useEffect(() => {
    if (auth.isLoading) return;
    if (!auth.isAuthenticated) {
      window.location.href = '/';
    }

    const token = auth.user?.access_token ?? '';

    const fetchData = async () => {
      try {
        const userInfo = await ProfileService.getUserInfo(token);
        setName(userInfo.name);

        if (userInfo.teamId) {
          setInTeam(true); // Set inTeam if user is in a team
        } else {
          setInTeam(false);
        }

        setHasCv(!!userInfo.CV);
      } catch (error) {
        console.error('Failed to fetch user data: ', error);
      }
    };

    void fetchData();
  }, [auth]);

  const handleConfirmLeave = async () => {
    const token = auth.user?.access_token ?? '';
    await ProfileService.deleteAccount(token)
      .then(auth.removeUser)
      .finally(() => (window.location.href = '/'));
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      p="20px"
    >
      <Text fontSize="4xl" mb="2rem" fontWeight="bold">
        Profilul meu
      </Text>
      <Box
        bg="rgba(2, 26, 40, 0.8)"
        borderRadius="50px"
        p="3rem"
        width={['100%', '100%', '100%']}
        maxWidth="1000px"
        boxShadow="0px 0px 10px 2px rgba(0, 0, 0, 0.2)"
        border="2px solid #249de7"
      >
        <Grid templateColumns={['1fr', '1fr 1fr']} gap={20} mt="1rem">
          <GridItem>
            <FormControl mb="1rem">
              <FormLabel color="#C4C4C4">Nume utilizator</FormLabel>
              <Box display="flex">
                <Text
                  bg="transparent"
                  borderColor="gray.400"
                  borderWidth="1px"
                  color="white"
                  p="0.5rem"
                  borderRadius="md"
                  _hover={{ borderColor: 'gray.600' }}
                  _focus={{ borderColor: 'teal.400' }}
                  width="100%"
                >
                  {name}
                </Text>
              </Box>
              <Text fontSize="sm" mt="0.5rem" color="#ABABAB">
                Preluat din SSO. Nu se poate modifica.
              </Text>
            </FormControl>

            <Box mt="1rem" display="flex" flexDirection="column" alignItems="center">
              <Text
                fontSize="sm"
                mt="0.5rem"
                color="#ABABAB"
                textAlign="center"
                mb="0.5rem"
                width="60%"
              >
                Dacă au apărut modificări poți actualiza datele personale.
              </Text>
              <Button
                bg="transparent"
                borderColor="#919191"
                borderWidth="1px"
                color="#white"
                _hover={{ borderColor: 'white', color: 'white' }}
                w="60%"
                onClick={onUpdateOpen}
              >
                Actualizează date
              </Button>
            </Box>
          </GridItem>

          <GridItem>
            {inTeam ? (
              <TeamSection /> // No need to pass teamCode or onCopyCode
            ) : (
              <JoinTeamSection />
            )}
          </GridItem>
        </Grid>

        <Button
          bg="red.500"
          color="white"
          _hover={{ bg: 'red.600' }}
          mt="2rem"
          w="full"
          onClick={onLeaveOpen}
        >
          Șterge contul
        </Button>
      </Box>
      {hasCv ? (
        <></>
      ) : (
        <Box
          h="5vh"
          mt="2%"
          borderRadius="30px"
          justifyContent="center"
          alignContent="center"
          w="fit-content"
          bg="red.500"
        >
          <Text fontSize={['4vw', '3vw', '2vw', '1vw']} textAlign="center" mx="20px">
            Nu ați încărcat niciun CV!
          </Text>
        </Box>
      )}
      <UpdateProfileModal
        isOpen={isUpdateOpen}
        onClose={onUpdateClose}
        onConfirmClose={() => (window.location.href = '/profile')}
      />
      <DeleteProfileModal
        isOpen={isLeaveOpen}
        onClose={onLeaveClose}
        onConfirmLeave={handleConfirmLeave}
      />
    </Box>
  );
};

export default MyProfile;
