import React from 'react';
import { IconButton, Image } from '@chakra-ui/react';
interface ArrowProps {
  type: string;
  src: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const ArrowButton: React.FC<ArrowProps> = ({ className, style, onClick, type, src }) => {
  return (
    <IconButton
      icon={<Image h="100%" w="75%" src={src} alt="arrow" />}
      onClick={onClick}
      aria-label="Next"
      position="absolute"
      {...(type === 'left' ? { left: '-10' } : { right: '-10' })}
      top={['62.5%', '67.5%', '50%']}
      transform="translateY(-50%)"
      zIndex="2"
      backgroundColor="transparent"
    />
  );
};
