import axios, { AxiosRequestConfig } from 'axios';

const Utils = {
  PostRequest: async (urlPath: string, data: any, headers?: any) => {
    // Accept headers as an optional parameter
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.post(url, data, { headers }); // Include headers in the request

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },

  PatchRequest: async (urlPath: string, data: any, headers?: any) => {
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.patch(url, data, { headers });

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },

  GetRequest: async (
    urlPath: string,
    headers?: any,
    responseType: AxiosRequestConfig['responseType'] = 'json',
  ) => {
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.get(url, { headers, responseType });

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },
  PutRequest: async (urlPath: string, data: any, headers?: any) => {
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.put(url, data, { headers });

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },

  DeleteRequest: async (urlPath: string, headers?: any) => {
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.delete(url, { headers });

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },

  CheckDate: (date: string | number | Date) => {
    const targetDate = new Date(date);
    const currentDateRO = new Date().toLocaleString('en-US', { timeZone: 'Europe/Bucharest' });
    const targetDateROString = targetDate.toLocaleString('en-US', { timeZone: 'Europe/Bucharest' });

    if (targetDateROString >= currentDateRO) return true;
    return false;
  },
};

export default Utils;
