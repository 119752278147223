import React, { useEffect, useState } from 'react';
import One from './assets/image 1.png';
import Two from './assets/image 2.png';
import Three from './assets/image 3.png';
import Four from './assets/image 4.png';
import Five from './assets/image 5.png';
import Six from './assets/image 6.png';
import Seven from './assets/image 7.png';
import Eight from './assets/image 8.png';
import Nine from './assets/image 9.png';
import Zero from './assets/image 0.png';
import Dots from './assets/image.png';
import { Box, VStack, Text, Image, HStack, Button } from '@chakra-ui/react';
import Background from './assets/background.png';
import HackLogo from '../../common/assets/hackitall.png';
import Ring from './assets/ring.png';
import CountdownRing from './components/CountdownRing';

const Countdown = () => {
  return (
    <Box
      backgroundImage={Background}
      bgSize="cover"
      bgPosition="center"
      position="absolute"
      overflowX="hidden"
      top="0"
      height={['140vh', '180vh', '190vh']}
      style={{ filter: 'brightness(1.4)' }}
    >
      <VStack
        justifyContent="center"
        height="100vh"
        width="100vw"
        spacing={0}
        alignContent="center"
        position="relative"
      >
        <Image
          src={HackLogo}
          w={['60vw', '45vw', '30vw']}
          zIndex={1}
          position="absolute"
          top={['15vh', '10vh']}
        />
        <Box position="relative" top={['0vh', '0vh', '5vh', '10vh', '25.5vh']}>
          <CountdownRing />
        </Box>
        <VStack width="100vw" alignContent="center" position="relative" mb="10vh">
          <Text
            color="#F4A6D6"
            filter="blur(30.2px)"
            fontFamily="Cocogoose"
            fontSize={['7vw', '6vw', '5vw']}
            position="absolute"
            top="0vh"
          >
            HackITall se apropie!
          </Text>
          <Text
            color="#8FD8EA"
            fontFamily="Cocogoose"
            fontSize={['7vw', '6vw', '5vw']}
            position="absolute"
            top={['0vh', '0vh']}
          >
            HackITall se apropie!
          </Text>
          <Text
            fontFamily="Roboto"
            fontSize={['5vw', '4vw', '2vw']}
            color="#C4C4C4"
            textAlign="center"
            width="100vw"
            px="20vw"
            position="absolute"
            top={['12.5vh', '27.5vh']}
            mb="5vh"
          >
            Începe numărătoarea inversă pentru aventura de 24 de ore care îți va pune la încercare
            creativitatea și abilitățile tehnice! Alătură-te studenților pasionați și celor mai tari
            companii IT pentru a dezvolta proiecte inovatoare și a câștiga premii extraordinare.
            Acum e momentul tău – ești pregătit?
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Countdown;
