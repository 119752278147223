import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Alert } from '@chakra-ui/react';
import CreateTeamModal from '../pop-up/CreateTeamModal'; // Adjust the import path as necessary
import ProfileService from '../services/ProfileService'; // Import the ProfileService
import { useAuth } from 'react-oidc-context';

const JoinTeamSection: React.FC = () => {
  const [teamCode, setTeamCode] = useState('');
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [error, setError] = useState(''); // State for error messages
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const auth = useAuth();

  const handleJoinTeam = async () => {
    const token = auth.user?.access_token ?? '';
    if (!token) {
      setError('Failed to join team. No token found.');
      return;
    }
    try {
      await ProfileService.joinTeam(token, teamCode);
      setSuccessMessage('Successfully joined the team!');
      setError('');
      window.location.reload();
    } catch (err) {
      setError('Failed to join team. Please try again.');
      setSuccessMessage('');
      console.error('Error joining team: ', err);
    }
  };
  const handleOpenCreateTeamModal = () => setIsCreateTeamModalOpen(true);
  const handleCloseCreateTeamModal = () => {
    setIsCreateTeamModalOpen(false);
    setError(''); // Clear any error when modal is closed
  };

  // Updated function to handle team creation
  const handleCreateTeam = async () => {
    // if (!tokenString) {
    //   setError('Failed to create team. No token found.');
    //   return;
    // }

    // try {
    // Call the createTeam method from ProfileService
    // await ProfileService.createTeam(token.access_token, teamName, githubPage);
    handleCloseCreateTeamModal(); // Close modal after creation
    window.location.reload();

    // } catch (err) {
    //   // setError('Failed to create team. Please try again.'); // Set error message
    //   // console.error('Error creating team: ', err);
    // }
  };

  return (
    <>
      <FormControl mb="1rem">
        <FormLabel color="#C4C4C4">Introdu codul echipei la care vrei să te alături</FormLabel>
        <Input
          value={teamCode}
          onChange={e => setTeamCode(e.target.value)}
          placeholder="Codul echipei"
          bg="transparent"
          borderColor="gray.400"
          color="white"
          _hover={{ borderColor: 'gray.600' }}
          _focus={{ borderColor: 'teal.400' }}
          mb="1rem"
        />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            bg="teal.500"
            borderColor="teal.500"
            borderWidth="1px"
            color="white"
            _hover={{ bg: 'teal.600' }}
            onClick={handleJoinTeam}
            mb="1rem"
            w="60%"
          >
            Intră în echipă
          </Button>
          <Button
            bg="transparent"
            borderColor="#919191"
            borderWidth="1px"
            color="white"
            _hover={{ borderColor: 'white', color: 'white' }}
            onClick={handleOpenCreateTeamModal}
            w="60%"
          >
            Creează o echipă
          </Button>
        </Box>
      </FormControl>

      {/* Show error message if there's an error */}
      {error && (
        <Alert status="error" mb="1rem" backgroundColor="red">
          {error}
        </Alert>
      )}
      {/* Show success message if the user successfully joined a team */}
      {successMessage && (
        <Alert status="success" mb="1rem" backgroundColor="green">
          {successMessage}
        </Alert>
      )}

      {/* Create Team Modal */}
      <CreateTeamModal
        isOpen={isCreateTeamModalOpen}
        onClose={handleCloseCreateTeamModal}
        onTeamCreated={handleCreateTeam} // Updated to not pass rankings
      />
    </>
  );
};
export default JoinTeamSection;
