import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Text,
  Input,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';
import ProfileService from '../services/ProfileService';
import { useAuth } from 'react-oidc-context';
import KickUserModal from './kickModal';
import { useNavigate } from 'react-router-dom';

const ManageTeamModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const [teamName, setTeamName] = useState('');
  const [githubPage, setGithubPage] = useState('');
  const [rankings, setRankings] = useState<number[]>([]);
  const [events, setEvents] = useState<{ id: string; name: string }[]>([]);
  const [teamMembers, setTeamMembers] = useState<{ id: string; name: string }[]>([]);
  const [captainId, setCaptainId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [kickModalOpen, setKickModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<{ id: string; name: string } | null>(null);
  const auth = useAuth();
  const [areRankingsValid, setAreRankingsValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeamData = async () => {
      const token = auth.user?.access_token ?? '';
      try {
        setLoading(true);
        const teamData = await ProfileService.fetchTeamData(token);
        const fetchedEvents = await ProfileService.fetchEvents(token);
        setTeamName(teamData.name);
        setGithubPage(teamData.githubUrl);
        setTeamMembers(teamData.members);
        setEvents(fetchedEvents);
        setRankings(
          fetchedEvents.map((event: { id: string }) =>
            (teamData.teamPreferences as Array<{ eventId: string }>)
              .map(p => p.eventId)
              .includes(event.id)
              ? (teamData.teamPreferences as Array<{ eventId: string }>).findIndex(
                  p => p.eventId == event.id,
                ) + 1
              : 0,
          ),
        );

        setCaptainId(teamData.capitanId);

        const currentUserId = auth.user?.profile.sub;
        const updateTeamMembers =
          currentUserId === teamData.capitanId
            ? teamData.members.filter((member: { id: string }) => member.id !== currentUserId)
            : teamData.members;
        setTeamMembers(updateTeamMembers);
      } catch (error) {
        setError('Failed to fetch team data.');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && auth.user?.access_token) {
      fetchTeamData();
    }
  }, [isOpen, auth.user?.access_token]);

  useEffect(() => {
    validateForm();
  }, [rankings]);

  const handleRankingChange = (index: number, value: number) => {
    const newRankings = [...rankings];
    newRankings[index] = value;
    setRankings(newRankings);
  };

  const handleResetRankings = () => {
    setRankings(Array(events.length).fill(0));
  };

  const validateForm = () => {
    const hasNonDefault = rankings.some(ranking => ranking !== 0 && !isNaN(ranking));
    setAreRankingsValid(hasNonDefault);
  };

  const handleSave = async () => {
    setLoading(true);
    setError('');

    const token = auth.user?.access_token ?? undefined;
    if (!token) {
      setLoading(false);
      return;
    }

    try {
      await ProfileService.updateTeam(token, teamName, githubPage, rankings);
      onClose();
    } catch (error) {
      setError('Failed to update team. Please try again.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKickMember = async () => {
    const token = auth.user?.access_token ?? '';
    if (!selectedMember) return;

    try {
      await ProfileService.kickMember(token, selectedMember.id);
      setTeamMembers(prev => prev.filter(member => member.id !== selectedMember.id));
      setKickModalOpen(false);
    } catch (error) {
      setError('Failed to kick member.');
    }
  };

  const openKickModal = (member: { id: string; name: string }) => {
    setSelectedMember(member);
    setKickModalOpen(true);
  };

  // Used to disable already selected rankings
  const usedRankings = new Set(rankings.filter(r => r !== 0));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent bg="#021a28" color="white" borderRadius="20px">
        <ModalHeader fontWeight="bold" fontSize="2xl" textAlign="center">
          Gestionează echipa
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <Alert status="error" mb="1rem" backgroundColor="red">
              <AlertIcon color="black" />
              {error}
            </Alert>
          )}
          <FormControl mb="1rem">
            <FormLabel>Numele echipei</FormLabel>
            <Input
              value={teamName}
              onChange={e => setTeamName(e.target.value)}
              placeholder="Numele echipei"
              bg="#020615"
              color="white"
            />
          </FormControl>
          <Text mb="1rem">Alege ordinea preferințelor la probe: (minim 1)</Text>
            <Button colorScheme="blue" mb="1rem" onClick={() => navigate('/events')}>
              Informații despre probe
            </Button>
          <Text fontSize="sm" mt="0.5rem" color="#ABABAB" mb="0.5rem" width="60%">
            {`1 este proba la care vreți cel mai mult sa participați. "Neselectată" înseamnă că nu
            doriți sa participați la acea probă.`}
          </Text>
          {events.map((event, index) => (
            <FormControl key={event.id} mb="1rem">
              <FormLabel>{event.name}</FormLabel>
              <Select
                placeholder="Neselectată"
                value={rankings[index] || ''}
                onChange={e => handleRankingChange(index, parseInt(e.target.value))}
                bg="black"
                color="white"
                css={{
                  option: {
                    backgroundColor: 'black',
                    color: 'white',
                  },
                }}
              >
                {Array.from({ length: events.length }, (_, i) => (
                  <option
                    key={i}
                    value={i + 1}
                    disabled={usedRankings.has(i + 1) && rankings[index] !== i + 1}
                  >
                    {i + 1}
                  </option>
                ))}
              </Select>
            </FormControl>
          ))}

          <Button colorScheme="yellow" mb="1rem" onClick={handleResetRankings}>
            Resetează preferințele
          </Button>

          <Text mb="1rem">Membrii echipei:</Text>
          <UnorderedList color="white" listStyleType="none">
            {teamMembers.map((member, index) => (
              <ListItem
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="0.5rem"
              >
                <Text>{member.name}</Text>
                {captainId === auth.user?.profile.sub && (
                  <Button colorScheme="red" onClick={() => openKickModal(member)}>
                    Kick
                  </Button>
                )}
              </ListItem>
            ))}
          </UnorderedList>

          {loading && <Spinner size="lg" />}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            disabled={!areRankingsValid}
            onClick={handleSave}
            isLoading={loading}
          >
            Salvează schimbările
          </Button>
        </ModalFooter>
      </ModalContent>

      <KickUserModal
        isOpen={kickModalOpen}
        onClose={() => setKickModalOpen(false)}
        onConfirmKick={handleKickMember}
        userName={selectedMember?.name || ''}
      />
    </Modal>
  );
};

export default ManageTeamModal;
