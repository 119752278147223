import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import ProfileService from '../services/ProfileService';
import { useAuth } from 'react-oidc-context';

interface LeaveTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmLeave: () => void;
}

const LeaveTeamModal: React.FC<LeaveTeamModalProps> = ({ isOpen, onClose, onConfirmLeave }) => {
  const [isCaptain, setIsCaptain] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const fetchTeamData = async () => {
      const token = auth.user?.access_token ?? '';
      try {
        const teamData = await ProfileService.fetchTeamData(token);
        const userId = auth.user?.profile.sub; // Assuming the user's ID is stored in `sub`

        // Check if the current user is the team captain
        if (teamData.capitanId === userId) {
          setIsCaptain(true);
        } else {
          setIsCaptain(false);
        }
      } catch (error) {
        console.error('Failed to fetch team data:', error);
      }
    };

    if (isOpen && auth.user?.access_token) {
      fetchTeamData(); // Fetch team data when the modal is opened
    }
  }, [isOpen, auth.user?.access_token]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="rgba(2, 26, 40, 0.9)"
        borderRadius="20px"
        border="2px solid #249de7"
        color="white"
      >
        <ModalHeader>{isCaptain ? 'Șterge echipa' : 'Părăsește echipa'}</ModalHeader>
        <ModalBody>
          {isCaptain ? (
            <Text>
              Ești căpitanul echipei. Dacă părăsești echipa aceasta va fi ștearsă permanent. Ești
              sigur că vrei să continui?
            </Text>
          ) : (
            <Text>Ești sigur că vrei să îți părăsești echipa?</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="transparent"
            borderColor="gray.400"
            borderWidth="1px"
            color="white"
            _hover={{ bg: 'gray.600' }}
            onClick={onClose}
            mr={3}
          >
            Anulează
          </Button>
          <Button bg="red.500" color="white" _hover={{ bg: 'red.600' }} onClick={onConfirmLeave}>
            {isCaptain ? 'Șterge echipa 😞' : 'Părăsește echipa 😞'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeaveTeamModal;
