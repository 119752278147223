import {
  VStack,
  Text,
  Box,
  Collapse,
  Button,
  HStack,
  Image,
  Input,
  Textarea,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Open from './assets/Open.svg';
import Close from './assets/Closed.svg';

import { ArrowButton } from '../../common/components/Button/ArrowIconButton';
import Next from '../../common/assets/Next.png';
import Prev from '../../common/assets/Previous.png';

import IT from './assets/Team/IT.png';
import Design from './assets/Team/Design.png';
import Logistica from './assets/Team/Logi.png';
import FR from './assets/Team/FR.png';
import HR from './assets/Team/HR.png';
import PR from './assets/Team/PR.png';
import MM from './assets/Team/MM.png';
import Mentor1 from './assets/Team/M1.png';
import Mentor2 from './assets/Team/M2.png';
import Mentor3 from './assets/Team/M3.png';
import Vice from './assets/Team/Vice.png';
import MP from './assets/Team/MP.png';

import { useAuth } from 'react-oidc-context';
import ContactService from './services/ContactService';

const Contact = () => {
  const auth = useAuth();

  const questions = [
    {
      question: 'Cine este juriul?',
      answer: 'Juriul este format din reprezentanți ai sponsorului principal.',
    },
    {
      question: 'Cine selectează echipele?',
      answer:
        'Echipele participante sunt alese de către sponsorul competiției pe baza CV-urilor participanților.',
    },
    {
      question: 'Mă pot înscrie la mai multe probe de hackathon?',
      answer: 'Sigur, dar în final vei fi selectat doar pentru una dintre ele.',
    },
    {
      question: 'Pot participa dacă nu sunt de la Facultatea de Automatică și Calculatoare?',
      answer: 'Da, poți! Singura restricție aplicată este ca toți membrii să fie studenți.',
    },
    {
      question: 'Pot să mă înscriu la HackITall chiar dacă ăsta este primul meu hackathon?',
      answer:
        'Da, vă încurajăm să vă înscrieți! Această experiență vă oferă noi oportunități și puteți învăța să creați o aplicație de la zero, sub presiunea timpului.',
    },
    {
      question: 'La cine pot apela în cazul în care am probleme?',
      answer:
        'Orice problemă poate fi semnalată membrilor echipei de organizare. În plus, în timpul competiției, te poți adresa voluntarilor evenimentului.',
    },
    {
      question: 'Dacă mă înscriu la competițiile mai multor companii pot schimba preferințele?',
      answer:
        'Da, până la încheierea  perioadei de înscrieri, preferințele pentru participarea la hackathonul unei anumite companii se pot schimba din pagina contului cu care s-a făcut înregistrarea.',
    },
    {
      question: 'Pot să mă înscriu de unul singur sau cu o echipă incompletă?',
      answer:
        'Nu, pentru a avea o înscriere validă în cadrul competiției este necesar ca echipa ta să fie formată din 3 membri.',
    },
  ];

  const [openIndex, setOpenIndex] = useState(-1);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const toast = useToast();
  const handleSend = async () => {
    const token = auth.user?.access_token ?? '';

    try {
      toast({
        title: 'Mesajul se trimite...',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });

      await ContactService.sendMessage(token, email, message);

      toast.closeAll();

      toast({
        title: 'Mesaj trimis cu succes!',
        description: 'Vă mulțumim pentru interesul acordat!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setEmail('');
      setMessage('');
    } catch (error) {
      toast.closeAll();

      console.log('Error sending the mail for contact: ');
      console.log(error);
      toast({
        title: 'A fost o eroare!',
        description: 'Încercați din nou.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const teamMembers = [
    { name: 'Gălățeanu Alexandru', title: 'Manager Proiect', image: MP },
    { name: 'Alexe Claudiu', title: 'Manager IT', image: IT },
    { name: 'Alexe Adelina', title: 'Manager Logistica', image: Logistica },
    { name: 'Dumitru Andreea', title: 'Manager Design', image: Design },
    { name: 'Manea Andrei', title: 'Manager Fundraising', image: FR },
    { name: 'Roșu Alexandru', title: 'Manager HR', image: HR },
    { name: 'Ghiorghiță Alexandra', title: 'Manager PR', image: PR },
    { name: 'Costea Carmen', title: 'Manager Multimedia', image: MM },
    { name: 'Niță Eduard', title: 'Mentor', image: Mentor1 },
    { name: 'Tudor Diana', title: 'Mentor', image: Mentor2 },
    { name: 'Popescu Alexandra', title: 'Mentor', image: Mentor3 },
    { name: 'Schmidt Andrei', title: 'Vicepresedinte Responsabil', image: Vice },
  ];

  return (
    <VStack spacing={['7.5vh', '17.5vh']} mt="5vh">
      <Text fontFamily="Cocogoose" fontSize={['8vw', '4vw']} color="#DEDEDE">
        FAQ
      </Text>
      <VStack spacing="2.5vh" width="100vw" flex={0}>
        {questions.map((q, index) => (
          <Box key={index} width={['80vw', '50vw']}>
            <HStack onClick={() => handleToggle(index)} width="100%">
              <Image src={openIndex === index ? Open : Close} boxSize={['15px', '20px', '30px']} />
              <Text
                textIndent="20px"
                fontFamily="Cocogoose"
                fontSize={['4vw', '1.5vw']}
                color="#DEDEDE"
              >
                {q.question}
              </Text>
            </HStack>
            <hr style={{ width: '100%', borderColor: '#26ACFD', margin: '2vh 0' }} />
            <Collapse in={openIndex === index} animateOpacity>
              <Text
                my="1vh"
                fontFamily="Roboto"
                fontSize={['4vw', '1.5vw']}
                fontWeight="600"
                position="relative"
                width="100%"
                pr="5%"
                whiteSpace="normal"
                wordBreak={'break-word'}
                left="2.5vw"
                textAlign="left"
                color="#ABABAB"
              >
                {q.answer}
              </Text>
            </Collapse>
          </Box>
        ))}
      </VStack>
      <Text fontFamily="Cocogoose" fontSize={['8vw', '4vw']} color="#DEDEDE">
        Contactați-ne
      </Text>
      <Box
        width={['80vw', '50vw']}
        height={['60vh', '75vh']}
        filter={'drop-shadow(0px 0px 15px #26ACFD) '}
        backgroundColor="#001C28E5"
        border="2px solid #26ACFD"
        borderRadius="20px"
      >
        <VStack width="100%" height="100%" px="10%" pt="2.5%">
          <Text
            fontFamily="Roboto"
            fontWeight="600"
            fontSize={['6vw', '2vw']}
            color="#FFFFFF"
            textAlign="left"
            width="100%"
          >
            Email:
          </Text>
          <Input
            width="100%"
            height="10%"
            fontSize="24px"
            fontFamily="Roboto"
            border="2px solid #919191"
            value={email}
            onChange={e => setEmail(e.target.value)}
          ></Input>
          <Text
            fontFamily="Roboto"
            fontWeight="600"
            fontSize={['6vw', '2vw']}
            color="#FFFFFF"
            textAlign="left"
            width="100%"
          >
            Subiect:
          </Text>
          <Textarea
            height="50%"
            fontSize="24px"
            fontFamily="Roboto"
            border="2px solid #919191"
            value={message}
            onChange={e => setMessage(e.target.value)}
          ></Textarea>
          <Button
            mt="5%"
            mb="5%"
            height="10%"
            width="50%"
            fontSize={['4vw', '1.5vw']}
            fontFamily="Cocogoose"
            color="#919191"
            border="2px solid #0277BD"
            borderRadius="30px"
            onClick={handleSend}
          >
            Trimite
          </Button>
        </VStack>
      </Box>
      <Text fontFamily="Cocogoose" fontSize={['8vw', '4vw']} color="#DEDEDE">
        Întâlnește echipa
      </Text>
      <Box width={['100vw', '90vw', '85vw']} mt="-10px" mb={['2vh', '10vh']}>
        <Slider
          swipeToSlide={true}
          slidesToShow={useBreakpointValue({ base: 3, md: 3, lg: 5 })}
          centerMode={true}
          centerPadding="0px"
          nextArrow={<ArrowButton type="right" src={Next} />}
          prevArrow={<ArrowButton type="left" src={Prev} />}
        >
          {teamMembers.map((member, index) => (
            <Box key={index} px={5} pt={5} h="100%">
              <VStack>
                <Box position="relative" boxSize={['15vw', '12.5vw']}>
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    borderRadius="full"
                    border="3px solid #015688"
                    filter="drop-shadow(0px 0px 10px #30B1FF)"
                    zIndex="1"
                  />
                  <Image
                    src={member.image}
                    alt={member.name}
                    boxSize="100%"
                    borderRadius="full"
                    zIndex="0"
                  />
                </Box>
                <Text
                  fontFamily="Cocogoose"
                  fontSize={['3vw', '1.5vw']}
                  textAlign="center"
                  color="#DEDEDE"
                >
                  {member.title}
                </Text>
              </VStack>
            </Box>
          ))}
        </Slider>
      </Box>
    </VStack>
  );
};

export default Contact;
