import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Alert,
  AlertIcon,
  Select,
  Text,
} from '@chakra-ui/react';
import ProfileService from '../services/ProfileService'; // Update the import path as necessary
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

interface CreateTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTeamCreated: (teamName: string, githubPage: string, rankings: number[]) => void; // Updated to accept rankings
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({ isOpen, onClose, onTeamCreated }) => {
  const [teamName, setTeamName] = useState('');
  const [githubPage, setGithubPage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [rankings, setRankings] = useState<number[]>([]); // State for rankings
  const [areRankingsValid, setAreRankingsValid] = useState(false);
  const [events, setEvents] = useState<{ id: string; name: string }[]>([]); // State for events (probes)
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(auth.isLoading);
  }, [auth.isLoading]);

  useEffect(() => {
    if (isOpen) {
      const fetchProbes = async () => {
        try {
          const token = auth.user?.access_token ?? undefined;
          if (token) {
            const fetchedEvents = await ProfileService.fetchEvents(token);
            setEvents(fetchedEvents);
            setRankings(Array(fetchedEvents.length).fill(0));
          }
        } catch (error) {
          setError('Failed to fetch events.');
        }
      };
      fetchProbes();
    }
  }, [isOpen, auth.user?.access_token]);

  useEffect(() => {
    validateForm();
  }, [rankings]);

  const handleRankingChange = (index: number, value: number) => {
    const newRankings = [...rankings];
    newRankings[index] = value;
    setRankings(newRankings);
  };

  const handleSave = async () => {
    setLoading(true);
    setError('');

    const token = auth.user?.access_token ?? undefined;

    if (!token) {
      setLoading(false);
      return;
    }

    try {
      await ProfileService.createTeam(token, teamName, githubPage, rankings); // Send rankings
      onTeamCreated(teamName, githubPage, rankings);
      onClose();
    } catch (error) {
      setError('Eroare la crearea echipei. Te rugăm încearcă din nou.');
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const hasNonDefault = rankings.some(ranking => ranking !== 0 && !isNaN(ranking));
    setAreRankingsValid(hasNonDefault);
  };

  const handleResetRankings = () => {
    setRankings(Array(events.length).fill(0)); // Reset rankings to 0
  };

  // Create a Set to keep track of used rankings
  const usedRankings = new Set(rankings.filter(r => r !== 0));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent bg="#021a28" color="white" borderRadius="20px">
        <ModalHeader fontWeight="bold" fontSize="2xl" textAlign="center">
          Creează Echipa
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <Alert status="error" mb="1rem" backgroundColor="red">
              <AlertIcon color="black" />
              {error}
            </Alert>
          )}
          <FormControl mb="1rem">
            <FormLabel>Team Name</FormLabel>
            <Input
              value={teamName}
              onChange={e => setTeamName(e.target.value)}
              placeholder="Introdu numele echipei"
              bg="#020615"
              color="white"
            />
          </FormControl>
          <Text mb="1rem">Alege ordinea preferințelor la probe: (minim 1)</Text>
          <Button colorScheme="blue" mb="1rem" onClick={() => navigate('/events')}>
            Informații despre probe
          </Button>
          <Text fontSize="sm" mt="0.5rem" color="#ABABAB" mb="0.5rem">
            1 este proba la care vreți cel mai mult sa participați. &quot;Neselectată&quot; înseamnă
            că nu doriți sa participați la acea probă.
          </Text>
          {events.map((event, index) => (
            <FormControl key={event.id} mb="1rem">
              <FormLabel>{event.name}</FormLabel> {/* Use event name */}
              <Select
                placeholder="Neselectată"
                value={rankings[index] || ''}
                onChange={e => handleRankingChange(index, parseInt(e.target.value))}
                bg="black" // Set background for the select dropdown
                color="white" // Set text color for better contrast
                css={{
                  option: {
                    backgroundColor: 'black', // Set background color for all option elements
                    color: 'white', // Ensure text is readable on a black background
                  },
                }}
              >
                {Array.from({ length: events.length }, (_, i) => (
                  <option
                    key={i}
                    value={i + 1}
                    disabled={usedRankings.has(i + 1) && rankings[index] !== i + 1}
                  >
                    {i + 1}
                  </option>
                ))}
              </Select>
            </FormControl>
          ))}
          <Button colorScheme="yellow" mb="1rem" onClick={handleResetRankings}>
            Resetează preferințele
          </Button>
          {loading && <Spinner size="lg" />}
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            colorScheme="blue"
            disabled={!areRankingsValid}
            onClick={handleSave}
            isLoading={loading}
          >
            Creează Echipa
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateTeamModal;
