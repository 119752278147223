import { Image, Flex, HStack, Link, Show, VStack, Button } from '@chakra-ui/react';
import Logo from '../../assets/hackitall.png';
import MyDrawer from './Drawer';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ProfileCompletedContext } from 'src/ProfileCompletedContext';
import MapService from 'src/pages/Map/services/MapService';

interface NavItem {
  name: string;
  link?: string;
}

const navList: NavItem[] = [
  { name: 'Acasa', link: '/' },
  { name: 'Probe', link: '/events' },
  { name: 'Sponsori', link: '/sponsors' },
  { name: 'Contact', link: '/contact' },
  { name: 'Harta', link: './map' },
];

const navList2: NavItem[] = [
  { name: 'Acasa', link: '/' },
  { name: 'Probe', link: '/events' },
  { name: 'Sponsori', link: '/sponsors' },
  { name: 'Contact', link: '/contact' },
  { name: 'Harta', link: './map' },
  { name: 'Profil', link: '/profile' },
];

const showRegister = process.env.REACT_APP_REGISTER === 'true';

const linkStyle = {
  textDecoration: 'none',
  fontWeight: 'bold',
  fontSize: '20px',
  color: '#DEDEDE',
  fontFamily: 'Cocogoose',
};

const Navbar = () => {
  const [isLogged, setIsLogged] = useState(false);
  const auth = useAuth();

  const profileCompleted = useContext(ProfileCompletedContext);

  useEffect(() => {
    if (auth.isLoading) return;

    // User is logged in
    setIsLogged(auth.isAuthenticated);

    const token = auth.user?.access_token ?? '';

    const fetchData = async () => {
      try {
        const usersTeamInfo = await MapService.fetchTeamData(token);

        if (!!usersTeamInfo || usersTeamInfo.status != 'ACCEPTED') {
          return;
        }

        navList2.push({ name: 'Map', link: '/map' });
      } catch (error) {
        console.error("Failed to fetch user's team data: ", error);
        return;
      }
    };

    void fetchData();
  }, [auth]);

  return (
    <VStack
      as={'header'}
      position={'fixed'}
      w={'100%'}
      spacing={0}
      backgroundColor="#1F1B2E"
      zIndex={1000}
    >
      <Flex w="100%" px="6" py="5" align="center" justify="space-between">
        <Link style={linkStyle} href="/">
          <Image src={Logo} fit="contain" maxW="auto" maxH="35px" />
        </Link>
        <Show breakpoint="(min-width: 860px)">
          <HStack as="nav" spacing={{ base: 3, lg: 7 }}>
            {profileCompleted || profileCompleted === null ? (
              (isLogged ? navList2 : navList).map((item, index) => (
                <Link
                  key={index}
                  href={item.link}
                  _hover={{ textDecor: 'none' }}
                  fontSize={{ base: '20px' }}
                  color="#DEDEDE"
                  fontFamily="Cocogoose"
                >
                  {item.name}
                </Link>
              ))
            ) : (
              <></>
            )}
            {showRegister ? (
              isLogged ? (
                <Button
                  fontSize={{ base: '20px' }}
                  color="#DEDEDE"
                  fontFamily="Cocogoose"
                  _hover={{ textDecor: 'none' }}
                  fontWeight={'400'}
                  onClick={() => {
                    void auth.removeUser().finally(() => {
                      window.location.href = '/';
                      setIsLogged(false);
                    });
                  }}
                >
                  Deconectare
                </Button>
              ) : (
                <Button
                  fontSize={{ base: '20px' }}
                  color="#DEDEDE"
                  fontFamily="Cocogoose"
                  _hover={{ textDecor: 'none' }}
                  fontWeight={'400'}
                  onClick={() => {
                    void auth.signinRedirect();
                  }}
                >
                  Logare
                </Button>
              )
            ) : (
              <></>
            )}
          </HStack>
        </Show>
        <Show breakpoint="(max-width: 860px)">
          {isLogged && showRegister ? (
            <MyDrawer navList={navList2} linkStyle={linkStyle} />
          ) : (
            <MyDrawer navList={navList} linkStyle={linkStyle} />
          )}
        </Show>
      </Flex>
    </VStack>
  );
};
export default Navbar;
