import { extendTheme } from '@chakra-ui/react';
import background from '../assets/background.png';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'background',
        bgImage: `url(${background})`,
        bgPosition: 'start',
        bgSize: 'cover',
        color: 'white',
      },
      fonts: {
        body: 'b Biger Over',
        heading: 'b Biger Over',
        mono: 'Menlo, monospace',
        roboto: 'Roboto',
        cocogoose: 'Cocogoose',
      },
    },
  },
  fonts: {
    heading: 'Mukta',
    body: 'Mukta',
  },
  colors: {
    primary: '#0277BD',
    background: '#0C122C',
    hackathon: '#03A9F4',
    heading: '#DEDEDE',
    gamejam: '#BB00FF',
    ctf: '#79F6FA',
    gray: '#ABABAB',
    white: '#FFF',
    hint: '#C4C4C4',
    error: 'red.500',
  },
});

export default theme;
