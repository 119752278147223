import { Box, Grid, VStack, Text } from '@chakra-ui/react';
import EventCard from './components/EventCard';
import React from 'react';

const Events = () => {
  const events = [
    {
      title: 'Algoritmica',
      description:
        'În cadrul acestei probe vei avea ocazia să demonstrezi că algoritmii nu mai prezintă pentru tine și echipa ta niciun impediment. Proba se desfășoară de-a lungul a 6 ore, problemele fiind jurizate ulterior în funcție de performanțele atinse. Aplică alături de cei mai potriviți doi coechipieri pentru a arăta că sunteți cei mai buni!',
      sponsorsIcons: [
        'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
        'https://360.uaic.ro/wp-content/uploads/2020/07/Amazon-logo-1.png',
        'https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png',
      ],
      type: 'Online',
      place: 'Online',
      date: '2022-01-01',
    },
    {
      title: 'Hackathon',
      description:
        'Hackathon is an event where you can collaborate and create innovative projects.',
      sponsorsIcons: [
        'https://360.uaic.ro/wp-content/uploads/2020/07/Amazon-logo-1.png',
        'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
        'https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png',
      ],
      type: 'In-Person',
      place: 'Tech Hub',
      date: '2022-02-15',
    },
    {
      title: 'Code Jam',
      description: 'Code Jam is a competitive programming event to test your coding skills.',
      sponsorsIcons: [
        'https://360.uaic.ro/wp-content/uploads/2020/07/Amazon-logo-1.png',
        'https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png',
        'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
      ],
      type: 'Hybrid',
      place: 'University Campus',
      date: '2022-03-10',
    },
    {
      title: 'AI Workshop',
      description: 'A workshop focused on the latest advancements in artificial intelligence.',
      sponsorsIcons: [
        'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
        'https://360.uaic.ro/wp-content/uploads/2020/07/Amazon-logo-1.png',
        'https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png',
      ],
      type: 'Online',
      place: 'Virtual',
      date: '2022-04-20',
    },
  ];

  // return (
  //   <VStack justifyContent="space-around" mb="10vh">
  //     <Text
  //       fontFamily="Cocogoose"
  //       fontSize={['12.5vw', '7vw', '5vw']}
  //       width="100%"
  //       mb="10vh"
  //       fontWeight="400"
  //       textAlign="center"
  //     >
  //       Probe
  //     </Text>
  //     <Grid
  //       px="5vw"
  //       templateColumns={['repeat(1,1fr)', 'repeat(1,1fr)', 'repeat(2, 1fr)']}
  //       width="100vw"
  //       justifyItems="center"
  //       alignItems="center"
  //       rowGap="10vh"
  //       columnGap="10vw"
  //     >
  //       {events.map((event, index) => (
  //         <EventCard
  //           key={index}
  //           title={event.title}
  //           description={event.description}
  //           sponsorsIcons={event.sponsorsIcons}
  //           type={event.type}
  //           place={event.place}
  //           date={event.date}
  //         />
  //       ))}
  //     </Grid>
  //   </VStack>
  // );

  return (
    <VStack width="100vw">
      <VStack
        filter={'drop-shadow(0px 0px 15px #26ACFD) '}
        backgroundColor="#001C28E5"
        border="2px solid #26ACFD"
        borderRadius="20px"
        justifyContent="space-around"
        mb="10vh"
        my="10vh"
        pb="10vh"
        px={['5%', '10%']}
        width={['90vw', '80vw', '70vw']}
        pt="5vh"
      >
        <Text
          fontFamily="Cocogoose"
          fontSize={['10vw', '7vw', '5vw']}
          width="100%"
          mb="10vh"
          fontWeight="400"
          textAlign="center"
        >
          Ce este un hackathon?
        </Text>
        <Text
          fontFamily="Roboto"
          fontSize={['3.5vw', '2.5vw', '1.5vw']}
          textAlign="center"
          color="#C4C4C4"
        >
          Un hackathon este un eveniment de programare intensiv, organizat pe parcursul a 24 de ore,
          în care participanții, adunați în echipe, lucrează împreună la dezvoltarea unui proiect
          digital. Hackathonul se desfășoară într-o locație dedicată, unde toți participanții sunt
          prezenți fizic. Atmosfera este una vibrantă și competitivă, fiind plină de energie și de
          creativitate.
          <br />
          <br /> În cadrul hackathonului nostru, echipele își aleg sponsorii la care doresc să
          participe încă de la înscriere. Fiecare sponsor are o probă cu o temă specifică, menită să
          provoace creativitatea și abilitățile echipelor. După ce echipele își manifestă
          preferințele, sponsorii selectează un număr de echipe pentru proba lor, pe baza CV-urilor
          trimise de participanți. În acest fel, fiecare sponsor poate alege echipele care se
          potrivesc cel mai bine cerințelor și obiectivelor sale.
          <br />
          <br /> Pe durata hackathonului, echipele au ca scop dezvoltarea unui prototip funcțional
          care să răspundă provocării propuse de sponsorul ales. Participanții colaborează intens,
          își împart sarcinile și vin cu idei diverse pentru a obține o soluție inovatoare și
          eficientă. La finalul celor 24 de ore, echipele își prezintă proiectele în fața unui
          juriu, care le evaluează în funcție de originalitate, impactul practic și calitatea
          tehnică a implementării.
        </Text>
      </VStack>
    </VStack>
  );
};

export default Events;
