import { TEAM_INFO_URL } from 'src/common/constants';
import Utils from 'src/common/utils';

const MapService = {
  fetchTeamData: async (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await Utils.GetRequest(TEAM_INFO_URL, headers);

    if (response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
};

export default MapService;
