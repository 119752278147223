// kickModal.tsx
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';

interface KickUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmKick: () => void;
  userName: string;
}

const KickUserModal: React.FC<KickUserModalProps> = ({
  isOpen,
  onClose,
  onConfirmKick,
  userName,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="rgba(2, 26, 40, 0.8)"
        borderRadius="20px"
        border="2px solid #249de7"
        color="white"
      >
        <ModalHeader>Kick</ModalHeader>
        <ModalBody>
          <Text>Ești sigur că vrei să elimini coechipierul? {userName}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="transparent"
            borderColor="#919191"
            borderWidth="1px"
            color="#ABABAB"
            _hover={{ borderColor: 'white', color: 'white' }}
            mr={3}
            onClick={onClose}
          >
            Anulează
          </Button>
          <Button
            bg="transparent"
            borderColor="#919191"
            borderWidth="1px"
            color="#ABABAB"
            _hover={{ borderColor: 'white', color: 'white' }}
            onClick={onConfirmKick}
          >
            Confirmă
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default KickUserModal;
