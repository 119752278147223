import { CONTACT_URL } from 'src/common/constants';
import Utils from 'src/common/utils';

const ContactService = {
  sendMessage: async (token: string, email: string, message: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const data = {
      email: email,
      message: message,
    };

    const response = await Utils.PostRequest(CONTACT_URL, data, headers);

    if (response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
};

export default ContactService;
