import {
  VStack,
  Image,
  Box,
  HStack,
  Button,
  Text,
  Link as ChakraLink,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import { useAuth } from 'react-oidc-context';
import Logo from '../../common/assets/hackitall.png';
import Countdown from '../Home/components/Countdown';

const showRegister = process.env.REACT_APP_REGISTER === 'true';

const Home = () => {
  const videoUrl = process.env.REACT_APP_VIDEO_URL;
  const StackComponent = useBreakpointValue({ base: VStack, md: HStack }) || VStack;
  const auth = useAuth();

  return (
    <VStack spacing={['10vh', '17.5vh', '25vh']} mt="10vh">
      <VStack spacing={['8vh', '12vh']}>
        <Image src={Logo} width="80%" />
        <Countdown targetDate={new Date('2024-12-08T11:30:00')} />
        <StackComponent justifyContent="center" spacing={['5vh', '2.5vw']}>
          {showRegister && !auth.isAuthenticated && (
            <Button
              backgroundColor="#26ACFD"
              borderRadius="10px"
              onClick={() => {
                void auth.signinRedirect();
              }}
              width={['70vw', '50vw', '40vw', '17.5vw']}
              height={['5vh', '7.5vh']}
            >
              <Text fontFamily="Cocogoose" fontSize={['4vw', '3vw', '1.5vw', '1.2vw']}>
                Inscrie-te acum
              </Text>
            </Button>
          )}
          <ScrollLink to="about" smooth={true} duration={500}>
            <Button
              backgroundColor="#26ACFD"
              borderRadius="10px"
              width={['70vw', '50vw', '40vw', '17.5vw']}
              height={['5vh', '7.5vh']}
            >
              <Text fontFamily="Cocogoose" fontSize={['4vw', '3vw', '1.5vw', '1.2vw']}>
                {' '}
                Afla mai multe
              </Text>
            </Button>
          </ScrollLink>
        </StackComponent>
      </VStack>
      <Box position="relative" width="70vw" height="0" paddingBottom="45%">
        <iframe
          src={videoUrl}
          allow="autoplay"
          allowFullScreen
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        ></iframe>
      </Box>
      <VStack spacing={['5vh', '10vh']} px={['5vw', '10vw', '20vw']} mb="10vh">
        <Element name="about" />
        <VStack
          spacing="5vh"
          p="5%"
          filter={'drop-shadow(0px 0px 15px #26ACFD) '}
          backgroundColor="#001C28E5"
          border="2px solid #26ACFD"
          borderRadius="20px"
        >
          <Text fontFamily="Cocogoose" fontSize={['12vw', '8vw', '4vw']} textAlign="center">
            Ce este HackITall?
          </Text>
          <Text
            fontFamily="Cocogoose"
            fontWeight="250"
            fontSize={['5vw', '3.5vw', '2vw']}
            textAlign="center"
            color="#90DCFE"
            marginBottom="2%"
          >
            HackITall este un eveniment dedicat studenților pasionați de domeniul IT&C, din cadrul
            facultăților cu profil tehnic.
          </Text>
          <VStack mb="5vh" spacing="5vh">
            <Text
              fontFamily="Roboto"
              fontSize={['3.5vw', '2.5vw', '1.5vw']}
              textAlign="center"
              color="#C4C4C4"
            >
              Organizat de LSAC (Liga Studenţilor din Facultatea de Automatică şi Calculatoare,
              Universitatea Politehnica Bucureşti), acest hackathon de mare anvergură urmărește, în
              special, apropierea dintre studenți și marile companii de profil, facilitând astfel
              oportunitățile de angajare ale participanților. Într-un cadru destins, amical, dar în
              aceeași măsură antrenant, studenții sunt încurajați să învețe, să pună întrebări,
              să-și lărgească orizontul de cunoaștere, astfel încât, la finalul celor 24 de ore, să
              ofere un proiect cu care se vor putea mândri în CV-urile personale și, de ce nu, să
              câștige premiile puse în joc. Înscrierea la HackITall vine cu un avantaj important
              pentru participanți: CV-urile lor vor fi analizate atât de companiile sponsor
              principal, cât și de firmele de recrutare. Tema hackathon-ului va fi propusă de
              companiile principale, care vor evalua și CV-urile la finalul perioadei de înscriere.
              În schimb, firmele de recrutare vor avea acces doar la CV-urile finale aferente probei
              de hackathon pe care o aleg. <br /> <br />
              Este momentul să arăți tot ce ai învățat și să te distrezi într-o nouă aventură
              palpitantă. Înscrie-te alături de cei mai buni doi camarazi și pornește în marea
              bătălie a liniilor de cod.
            </Text>
          </VStack>
        </VStack>

        {showRegister && (
          <Link to="/events">
            <Text
              fontFamily="Roboto"
              fontSize={['4.5vw', '3.5vw', '2.5vw']}
              textAlign="center"
              color="#26ACFD"
            >
              Află mai multe despre probele noastre
            </Text>
          </Link>
        )}
      </VStack>
    </VStack>
  );
};

export default Home;
