import React, { useEffect, useState } from 'react';
import { Text, VStack, HStack, Box } from '@chakra-ui/react';

interface CountdownProps {
  targetDate: Date;
}

interface TimeLeft {
  ore: number;
  minute: number;
  secunde: number;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  function calculateTimeLeft(): TimeLeft {
    const difference = targetDate.getTime() - new Date().getTime();
    if (difference > 0) {
      const zile = Math.floor(difference / (1000 * 60 * 60 * 24));
      const ore = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minute = Math.floor((difference / 1000 / 60) % 60);
      const secunde = Math.floor((difference / 1000) % 60);

      return { ore, minute, secunde };
    } else {
      return { ore: 0, minute: 0, secunde: 0 };
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const formatTimeUnit = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <VStack
      spacing={4}
      p="5%"
      backgroundColor="#001C28E5"
      border="2px solid #26ACFD"
      borderRadius="20px"
      boxShadow="0 0 20px #26ACFD"
    >
      <Text
        fontFamily="'Arial Black', sans-serif"
        fontSize={['5vw', '3.5vw', '2vw']}
        color="#90DCFE"
        textAlign="center"
      >
        Timp rămas pana la sfarsitul probelor:
      </Text>
      <HStack spacing={[6, 8, 9]}>
        {Object.entries(timeLeft).map(([label, value]) => (
          <Box key={label} textAlign="center">
            <Text
              fontFamily="'Arial Black', sans-serif"
              fontSize={['10vw', '7vw', '4vw']}
              color="#26ACFD"
            >
              {formatTimeUnit(value)}
            </Text>
            <Text
              fontFamily="'Arial Black', sans-serif"
              fontSize={['4vw', '3vw', '1.5vw']}
              color="#90DCFE"
            >
              {label}
            </Text>
          </Box>
        ))}
      </HStack>
    </VStack>
  );
};

export default Countdown;
