import axios from 'axios';
import { PageSchema, TableField } from 'admin_lsac';

const formatForCSV = (str: string) => {
  if (str.includes(',') || str.includes('"') || str.includes('\n')) {
    str = str.replace(/"/g, '""');
    str = `"${str}"`;
  }
  return str;
};

const downloadCSV = (csvContent: any, filename: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// TODO - find a better way to do this, now it works only for teams with 3 players and the captain is the first
const getMembers = () => {
  const members: TableField[] = [];

  for (let i = 1; i < 3; i++) {
    const member = {
      name: 'Member' + i + 'ID',
      access: (obj: any) => {
        if (!obj.members[i]) return 'None';
        return obj.members[i].id;
      },
    };

    members.push(member);
  }

  return members;
};

// TODO - same problem, a way to find the number of events
const getEvents = () => {
  const events: TableField[] = [];

  for (let i = 0; i < 3; i++) {
    const event = {
      name: 'EventSigned' + (i + 1),
      access: (obj: any) => {
        if (!obj.teamPreferences[i]) return 'None';
        return obj.teamPreferences[i].event.name;
      },
    };

    events.push(event);
  }

  return events;
};

export const pages: PageSchema[] = [
  {
    name: 'Teams',
    actions: [
      {
        name: 'Descarcă CSV',
        onSubmit: async obj1 => {
          try {
            const token = localStorage.getItem('access_token');
            const Headers = {
              Authorization: `Bearer ${token}`,
            };
            const res = await axios.get(process.env.REACT_APP_API_URL + 'team/allTeams', { headers: Headers });

            const nrMembers = 3;
            const nrPreferences = 3;

            const headers = [
              'Team Name',
              'CaptainID',
              ...Array.from({ length: nrMembers - 1 }, (_, i) => 'Member' + (i + 1) + 'ID'),
              ...Array.from({ length: nrPreferences }, (_, i) => 'EventSigned' + (i + 1)),
            ];
            const csvRows = [];

            csvRows.push(headers.join(','));
            res.data.forEach((team: any) => {
              const row = [
                formatForCSV(team.name),
                team.capitanId,
                ...Array.from({ length: nrMembers - 1 }, (_, i) => {
                  if (!team.members[i + 1]) return 'None';
                  return team.members[i + 1].id;
                }),
                ...Array.from({ length: nrPreferences }, (_, i) => {
                  if (!team.teamPreferences[i]) return 'None';
                  return formatForCSV(team.teamPreferences[i].event.name);
                }),
              ];
              csvRows.push(row.join(','));
            });

            const csvContent = csvRows.join('\n');
            downloadCSV(csvContent, 'teams.csv');
          } catch (e) {
            alert('eroare, uita-te in console.log');
            console.log(e);
          }
        },
      },
    ],
    rowActions: [
      {
        name: 'Disqualify Team',
        onSubmit: async (obj: any) => {
          console.log(obj);
          try {
            const token = localStorage.getItem('access_token');
            const Headers = {
              Authorization: `Bearer ${token}`,
            }; 
            axios.delete(process.env.REACT_APP_API_URL + 'team/' + obj, { headers: Headers });
          } catch (e) {
            console.error('EROARE: ' + e);
          }
        },
      },
    ],
    getRequest: async () => {
      try {
        const token = localStorage.getItem('access_token');
        const Headers = {
          Authorization: `Bearer ${token}`,
        };
        const res = await axios.get(process.env.REACT_APP_API_URL + 'team/allTeams', { headers: Headers });

        return res.data;
      } catch (e) {
        // window.location.href = '/login';
        return [];
      }
    },
    tableFields: [
      {
        name: 'Team Name',
        access: (obj: any) => obj.name,
      },
      {
        name: 'CapitanID',
        access: (obj: any) => obj.capitanId,
      },
      ...getMembers(),
      ...getEvents(),
    ],
  },
  {
    name: 'Users',
    actions: [
      {
        name: 'Descarcă CSV',
        onSubmit: async obj1 => {
          try {
            const token = localStorage.getItem('access_token');
            const Headers = {
              Authorization: `Bearer ${token}`,
            }; 
            const res = await axios.get(process.env.REACT_APP_API_URL + 'user/allUsers', { headers: Headers });

            const headers = ['Name', 'TeamID'];
            const csvRows = [];

            csvRows.push(headers.join(','));
            res.data.forEach((user: any) => {
              const row = [formatForCSV(user.name), user.teamId ? user.teamId : 'None'];
              csvRows.push(row.join(','));
            });

            const csvContent = csvRows.join('\n');
            downloadCSV(csvContent, 'users.csv');
          } catch (e) {
            alert('eroare, uita-te in console.log');
            console.log(e);
          }
        },
      },
    ],
    rowActions: [
      {
        name: 'Disqualify User',
        onSubmit: async (obj: any) => {
          try {
            const token = localStorage.getItem('access_token');
            const Headers = {
              Authorization: `Bearer ${token}`,
            }; 
            axios.delete(process.env.REACT_APP_API_URL + 'user/' + obj, { headers: Headers });
          } catch (e) {
            console.error('EROARE: ' + e);
          }
        },
      },
      {
        name: 'Remove From Team',
        onSubmit: async (obj: any) => {
          try {
            const token = localStorage.getItem('access_token');
            const Headers = {
              Authorization: `Bearer ${token}`,
            }; 
            axios.post(process.env.REACT_APP_API_URL + 'user/removeTeam/' + obj, { headers: Headers });
          } catch (e) {
            console.error('EROARE: ' + e);
          }
        },
      },
    ],
    getRequest: async () => {
      try {
        const token = localStorage.getItem('access_token');
        const Headers = {
          Authorization: `Bearer ${token}`,
        };
        const res = await axios.get(process.env.REACT_APP_API_URL + 'user/allUsers', { headers: Headers });

        return res.data;
      } catch (e) {
        // window.location.href = '/login';
        return [];
      }
    },
    tableFields: [
      {
        name: 'UserID',
        access: (obj: any) => obj.id,
      },
      {
        name: 'Name',
        access: (obj: any) => obj.name,
      },
      {
        name: 'TeamID',
        access: (obj: any) => (obj.teamId ? obj.teamId : 'None'),
      },
    ],
  },
];
