import { Box, Text, Image, Link, VStack, HStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import Go from '../assets/buton_maimulte.png';

interface SponsorCardProps {
  type: string;
  event: string;
  description: string;
  logo: string;
  link: string;
}

const SponsorCard: React.FC<SponsorCardProps> = ({ type, event, description, logo, link }) => {
  const [isActive, setisActive] = useState(false);

  return (
    <Box
      w="100%"
      h={['50vh', '55vh']}
      py="4.5%"
      px="7.5%"
      backgroundColor="#011B28E5"
      filter={'drop-shadow(0px 0px 10px #26ACFD) '}
      backdropFilter="blur(25.6px)"
      border="2px solid #26ACFD"
      borderRadius="20px"
      onClick={() => setisActive(!isActive)}
    >
      <HStack width="100%" justifyContent="space-between">
        <Text fontFamily="Roboto" fontSize="20px" color="#DEDEDE" fontWeight="600">
          Sponsor {type}
        </Text>
        <Text fontFamily="Roboto" fontSize="15px" fontWeight="600" color="#ABABAB">
          {event}
        </Text>
      </HStack>
      {isActive ? (
        <VStack height="95%">
          <Box height="80%" overflowY="auto" mt="2%" p="2">
            <Text
              fontFamily="Roboto"
              textAlign="center"
              fontSize={['12px', '15px']}
              color="#ABABAB"
            >
              {description}
            </Text>
          </Box>
          <HStack width="90%" height="20%" justifyContent="space-between" mb="5%">
            <Image
              src={logo}
              alt={`${type} logo`}
              objectFit="contain"
              width="50%"
              maxHeight="100%"
              maxWidth="70%"
            />
            <Link href={link} isExternal>
              <Image src={Go} boxSize="100%"></Image>
            </Link>
          </HStack>
        </VStack>
      ) : (
        <VStack height="90%" justifyContent="center">
          <Image src={logo} alt={`${type} logo`} boxSize="90%" objectFit="contain" />
        </VStack>
      )}
    </Box>
  );
};
export default SponsorCard;
