import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';

interface LeaveTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmLeave: () => void;
}

const DeleteProfileModal: React.FC<LeaveTeamModalProps> = ({ isOpen, onClose, onConfirmLeave }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="rgba(2, 26, 40, 0.8)"
        borderRadius="20px"
        border="2px solid #249de7"
        color="white"
      >
        <ModalHeader>Șterge contul</ModalHeader>
        <ModalBody>
          <Text>Ești sigur?</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="transparent"
            borderColor="gray.400"
            borderWidth="1px"
            color="white"
            _hover={{ bg: 'gray.600' }}
            onClick={onClose}
            mr={3}
          >
            Anulează
          </Button>
          <Button bg="red.500" color="white" _hover={{ bg: 'red.600' }} onClick={onConfirmLeave}>
            Șterge
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteProfileModal;
