// Authentication URLs
export const LOGIN_URL = 'teams/login';
export const FORGOT_PASSWORD_URL = 'teams/forgotPass';
export const CONTACT_URL = 'contact';
export const REGISTER_URL = 'teams/register';
export const VERIFY_URL = 'verifications/verifyEmail';
export const RESET_PASSWORD_URL = 'verifications/verifyReset';
export const VERIFICATION_URL = 'verifications/verifyEmail';

// Profile URLs
export const UPDATE_PROFILE_URL = 'user';
export const PROFILE_URL = 'user';
export const DELETE_PROFILE_URL = 'user';
export const ALL_USERS_URL = 'user/allUsers';
export const REMOVE_TEAM_MEMBER_URL = 'user/removeTeam/';
export const ADMIN_REMOVE_USER_URL = 'user/';

// Team URLs
export const TEAM_INFO_URL = 'team';
export const CREATE_TEAM_URL = 'team';
export const LEAVE_TEAM_URL = 'team';
export const ALL_TEAMS_URL = 'team/allTeams';
export const REMOVE_TEAM_MEMBER_CAPTAIN_URL = 'team/member/';
export const UPDATE_TEAM_URL = 'team';
export const JOIN_TEAM_URL = 'team/join/';
export const DISQUALIFY_TEAM_URL = 'team/';

// CV URLs
export const UPLOAD_CV_URL = 'cv';
export const DOWNLOAD_CV_URL = 'cv';
export const DELETE_CV_URL = 'cv';
export const DOWNLOAD_EVENT_CVS_URL = 'cv/all/';

// Misc URLs
export const TEAMMEMBER_CV_URL = 'teams/downloadCV';
export const UPDATE_PREFERENCES_URL = 'dragAndDrop';
export const TEST_DATE = '2024-01-10T20:00:00';

// Events URLs
export const EVENTS_URL = 'event';
